@import "@fortawesome/fontawesome-free/css/all.css";

.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}

.submit-form {
  max-width: 300px;
  margin: auto;
}

.edit-form {
  max-width: 300px;
  margin: auto;
}

.charts-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    justify-items: center;
}

.chart-item {
    padding: 15px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    min-width: 350px;
    height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
}
